import { Formik } from "formik";
import { useContext, useState } from "react";

import SearchArrow from "../../../images/chat-arrow.svg";
import ChatBotIcon from "../../../images/chat-bot-icon.svg";
import UserImage from "../../../images/chat-user.svg";

import DropChatIcon from "../../../images/drop-chat-icon.svg";
// import QuestionsSideBar from "./old-questions-sidebar";
import { DEVICE_MOBILE } from "../../../constants/breakpoints";
import { PublicContext } from "../../../context/PublicContext";
import { PUBLIC_USER_CHATBOT } from "../../../utils/class-names";
import * as BookChatStyles from "./../styled-public-trial";

// eslint-disable-next-line

const DummyPopupChatbox = () => {
  const { deviceWidth } = useContext(PublicContext);
  const { indexingProgress, setIndexingProgress, setBookTypeName } =
    useContext(PublicContext);

  return (
    <>
      <BookChatStyles.MainContainer fluid style={{ margin: "4%" }}>
        <BookChatStyles.RightColumn>
          <BookChatStyles.ChatBarHeader
            style={{ justifyContent: "start", color: "#fff" }}
          >
            <img style={{ height: "30px" }} src={DropChatIcon} alt="" />{" "}
            &nbsp;&nbsp;Personalized Demo
          </BookChatStyles.ChatBarHeader>

          <BookChatStyles.DummyChatInputBox style={{ paddingBottom: '2%' }}>
            <BookChatStyles.RightContentRow style={{
              backgroundColor: "#fff",
            }}>
              <BookChatStyles.ChatBotContent
                sm={12}
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <div style={{ flexDirection: 'column', alignItems: 'center' }}>
                  {" "}
                  <b style={{ fontSize: "16px", marginTop: "3%" }}>
                    How we look on your website
                  </b>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#797979",
                      margin: "0px",
                      marginTop: "2%",
                    }}
                  >
                    This is just a demo of the chatbot and how it will
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#797979",
                      margin: "0px",
                    }}
                  >
                    look on your website.
                  </p>
                </div>
                <BookChatStyles.QuestionAnswerSection
                  style={{ marginTop: "3%" }}
                >
                  <BookChatStyles.SignleQuestionAnswerSection>
                    <BookChatStyles.ResponseSection
                      style={{
                        marginBottom: "5%",
                        width: "65%",
                        background: "#f8f7fa",
                        // backgroundColor: "#fff",
                      }}
                    >
                      <div>
                        <img
                          width={deviceWidth === DEVICE_MOBILE ? "10" : "20"}
                          src={ChatBotIcon}
                          alt="user_image"
                          style={{
                            display: "flex",
                            alignSelf: "baseline",
                          }}
                        />
                        &nbsp;&nbsp;
                        <h5
                          style={{
                            margin: 0,
                            fontSize:
                              deviceWidth === DEVICE_MOBILE
                                ? "0.55rem"
                                : "0.85rem",
                          }}
                        >
                          Hi 👋 How can I help you today?
                        </h5>
                      </div>
                    </BookChatStyles.ResponseSection>

                    <BookChatStyles.QuestionSection
                      style={{
                        marginBottom: "5%",
                        width: "43%",
                        background: "#f8f7fa",
                        // backgroundColor: "#fff",
                      }}
                    >
                      <img
                        width={deviceWidth === DEVICE_MOBILE ? "10" : "20"}
                        src={UserImage}
                        alt="user_image"
                      />
                      &nbsp;&nbsp;
                      <h5
                        style={{
                          fontSize:
                            deviceWidth === DEVICE_MOBILE
                              ? "0.55rem"
                              : "0.85rem",
                        }}
                      >
                        What is Dropchat?
                      </h5>
                    </BookChatStyles.QuestionSection>
                    <BookChatStyles.ResponseSection
                      style={{
                        background: "#f8f7fa",
                        backgroundColor:
                          
                             "#f8f7fa",
                      }}
                    >
                      <div>
                        <img
                          width={deviceWidth === DEVICE_MOBILE ? "10" : "20"}
                          src={ChatBotIcon}
                          alt="user_image"
                          style={{
                            display: "flex",
                            alignSelf: "baseline",
                          }}
                        />
                        &nbsp;&nbsp;
                        <h5
                          style={{
                            margin: 0,
                            fontSize:
                              deviceWidth === DEVICE_MOBILE
                                ? "0.55rem"
                                : "0.85rem",
                          }}
                        >
                          Dropchat.co is a platform that allows you to create
                          chatbots and train them using content from websites,
                          PDFs, and YouTube videos.
                        </h5>
                      </div>
                    </BookChatStyles.ResponseSection>
                  </BookChatStyles.SignleQuestionAnswerSection>
                </BookChatStyles.QuestionAnswerSection>
              </BookChatStyles.ChatBotContent>

              <BookChatStyles.FieldColumn style={{  marginBottom: '5%', marginTop: '5%' }}>
                <Formik initialValues={{}} onSubmit={(values, actions) => { }}>
                  {({ errors, touched, isValidating }: any) => (
                    <BookChatStyles.BookFetchForm
                      style={{ padding: "0", paddingBottom: "6px" }}
                      className={PUBLIC_USER_CHATBOT}
                    >
                      <BookChatStyles.FieldSection>
                        <BookChatStyles.DummyFormInput
                          style={{
                            border: "2px solid lightgray",
                            paddingRight: "2.6rem",
                            resize: "none",
                            height: "46px",
                            background: "#f8f7fa",
                            padding: "11px",
                          }}
                        />
                        <BookChatStyles.DummyButton style={{ top: "10px" }}>
                          <img width={25} src={SearchArrow} alt="search" />
                        </BookChatStyles.DummyButton>
                      </BookChatStyles.FieldSection>
                    </BookChatStyles.BookFetchForm>
                  )}
                </Formik>
              </BookChatStyles.FieldColumn>
            </BookChatStyles.RightContentRow>
          </BookChatStyles.DummyChatInputBox>
        </BookChatStyles.RightColumn>
      </BookChatStyles.MainContainer>
    </>
  );
};

export default DummyPopupChatbox;
