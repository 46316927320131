import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from "formik";
import styled from "styled-components";
import { FieldSection, FormError } from "../styled-public";
import { FormLabel } from "react-bootstrap";

// interface BasicProps {
//     setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
// }
const FieldLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
`;

const FormInput = styled(Field)`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
`;

const FormInputTextarea = styled.textarea`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 7rem;
  overflow: hidden;
`;

const FieldSectionDiv = styled.div`
  margin-bottom: 2%;
  margin-top: 2%;
`;

const FieldSectionName = styled(FieldSection)`
  width: 100%;
`;

const FieldSectionEmail = styled(FieldSection)`
  width: 80%;
  margin-left: 3%;
`;

const BasicInfo = ({
  setFieldValue,
  values,
  errors,
  setIsEmailValidated,
  isEmailValidated,
}: any) => {
  const [formErrors, setFormErrors] = useState<any>(errors);
  // console.log("email error in errors: ",errors.email)
  // console.log("email error in formErrors: ",formErrors.email)
  useEffect(() => {
    setFormErrors(errors || []);
  }, [errors]);

  const validateEmail = (email: any) => {
    if (!email) {
      setFormErrors({ ...formErrors, email: "Required email" });
      // errors.email = 'Required email'
      setIsEmailValidated(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setFormErrors({ ...formErrors, email: "Invalid email address" });
      // errors.email = 'Invalid email address'
      setIsEmailValidated(false);
    } else {
      setFormErrors({ ...formErrors, email: "" });
      setIsEmailValidated(true);
    }
  };

  return (
    <>
      <h5>
        <b>Simple Chatbot Knowledge Base Setup</b>
      </h5>
      <h6>
        Just add a few details, and our AI will create a customized knowledge
        base for your chatbot. It’s quick and straightforward.
      </h6>

      <FieldSectionDiv>
        <FieldSectionName>
          <FieldLabel>Company Name</FieldLabel>
          <FormInput
            name="companyName"
            type="text"
            onChange={(e: any) => {
              setFieldValue(`companyName`, e.target.value);
            }}
          />
        </FieldSectionName>

        <FieldSectionEmail>
          <FieldLabel>Your Email*</FieldLabel>
          <FormInput
            name="email"
            type="email"
            onChange={(e: any) => {
              setFieldValue("email", e.target.value);
              validateEmail(e.target.value);
            }}
          />
          {formErrors.email || !isEmailValidated ? (
            <div style={{ color: "red" }}>{formErrors.email}</div>
          ) : (
            <></>
          )}
        </FieldSectionEmail>
      </FieldSectionDiv>

      <FieldSection>
        <FieldLabel>
          Maximum number of words per response (We suggest 50 words):
        </FieldLabel>
        <FormInputTextarea
          defaultValue={values.commpanyDetails}
          name="commpanyDetails"
          onChange={(e: any) => {
            setFieldValue(`commpanyDetails`, e.target.value);
          }}
        />
      </FieldSection>
    </>
  );
};

export default BasicInfo;
