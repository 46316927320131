import { useEffect, useRef, useState } from "react";
import { NotificationContainer } from "react-notifications";
// import { Helmet } from "react-helmet";
import axios from "axios";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import { getDeviceInfoForTrackEvent } from "./event-utils/dropchat-event-track";
import "./App.css";
import Callback from "./Callback";
import AppLoader from "./components/app-loader";
import BackgroundWrapper from "./components/backgroundWrapper";
import BookBubbleChat from "./components/bubble-chat/book-bubble-chat-renewed";
import CredentialLoginView from "./components/credential-login-view";
import CredentialSignupView from "./components/credential-signup-view";
import ForgotPasswordView from "./components/forgot-password-view";
import MagicLinkLoginView from "./components/magic-link-login-view";
// import DemoChatPage from "./components/publicComponent/demo-chat-page";
import styled from "styled-components";
import LoadingSpinner from "./LoadinSpinner";
import GuestBookChat from "./components/publicComponent/guest-book-chat";
import IframeBookChat from "./components/publicComponent/iframe-book-chat";
import LandingPage from "./components/publicComponent/landingPage";
import PublicBookChat from "./components/publicComponent/public-book-chat";
import SharedBookChat from "./components/publicComponent/shared-book-chat";
// import { LARGE, MEDIUM, MOBILE, TAB } from "./constants/breakpoints";
import BookContextProvider from "./context/BookContext";
import PublicContextProvider from "./context/PublicContext";
import UserContextProvider from "./context/UserContext";
import ChooseURLContextProvider from "./context/choose-url-context";
import CodeBoxContextProvider from "./context/code-box-context";
import LoaderContextProvider from "./context/loader-context";
import NotesContextProvider from "./context/notes-context";
import PaginationContextProvider from "./context/pagination-context";
import PlanContextProvider from "./context/plan-context";
import CallbackSocial from "./Callback-Social";
import TeamContextProvider from "./context/team-context";
import UserDemoChat from "./components/publicComponent/trial-chatbot/user-demo-chat";
// Declare _cio as a global variable
// declare var _cio: any;

const MainComponent = styled.div`
  width: 100%;
  margin: auto;
  height: 100vh;
`;

const Main = () => {
  const [isLoading, setIsLoading] = useState(false);
  const checkLoaginStatus = () => {
    try {
      const verifyCredentialRequired = localStorage.getItem(
        "verifyCredentialRequired"
      );

      if (verifyCredentialRequired === "yes") return true;
      else return false;
    } catch (error) {
      return false;
    }
  };
  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Router>
        <Routes>
          <Route
            path="/callback"
            element={<Callback loadingState={setIsLoading} />}
          />
          <Route
            path="/callback-social"
            element={<CallbackSocial loadingState={setIsLoading} />}
          />
          <Route
            path="/*"
            element={
              checkLoaginStatus() ? (
                <BackgroundWrapper loadingState={setIsLoading} />
              ) : (
                <LandingPage />
              )
            }
          />
          <Route
            path="/magic-login"
            element={<MagicLinkLoginView loadingState={setIsLoading} />}
          />
          {process.env.REACT_APP_ENV === "production" && (
            <>
              <Route
                path="/login"
                element={<CredentialLoginView loadingState={setIsLoading} />}
              />
              <Route
                path="/signup"
                element={<CredentialSignupView loadingState={setIsLoading} />}
              />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordView loadingState={setIsLoading} />}
              />
            </>
          )}
          <Route path="/landing" element={<LandingPage />} />
          {/* <Route path="/demo-chat-page" element={<DemoChatPage />} /> */}
          <Route path="/public/books/:bookId" element={<PublicBookChat />} />
          <Route path="/user-demo-chat/:bookId" element={<UserDemoChat />} />
          <Route
            path="/public/bubble-chat/:bookId/:header"
            element={<BookBubbleChat />}
          />
          <Route
            path="/public/bubble-chat/:bookId"
            element={<BookBubbleChat />}
          />
          <Route
            path="/public/shared-chat/:bookId"
            element={<SharedBookChat />}
          />
          <Route
            path="/public/iframe-chat/:bookId"
            element={<IframeBookChat />}
          />
          <Route
            path="/guest/books/:bookId"
            element={<GuestBookChat showBack={true} />}
          />
          <Route
            path="/guest-books/:bookId"
            element={<GuestBookChat showBack={false} />}
          />
        </Routes>
      </Router>
      <NotificationContainer />
    </>
  );
};

const App = () => {
  const [allScriptContent, setAllScriptContent] = useState<any>();
  // const [allHeaderScriptContent, setAllHeaderScriptContent] = useState<any>();

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVER_URL}/html-script-settings/script/footer`
  //     )
  //     .then((response: any) => {
  //       // console.log("inside footer response.", response.data);
  //       if (response.data) {
  //         setAllScriptContent(response.data);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log("error is, ", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVER_URL}/html-script-settings/script/header`
  //     )
  //     .then((response: any) => {
  //       // console.log("inside footer response.", response.data);
  //       if (response.data) {
  //         setAllHeaderScriptContent(response.data);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log("error is, ", error);
  //     });
  // }, [setAllHeaderScriptContent]);

  // console.log("allScriptContent is, ", allHeaderScriptContent);

  const ref: any = useRef();

  useEffect(() => {
    /* convert your HTML string into DocumentFragment*/
    const node = document
      .createRange()
      .createContextualFragment(allScriptContent ? allScriptContent : "");
    ref.current.appendChild(node);
  }, [allScriptContent]);
  return (
    <LoaderContextProvider>
      <PublicContextProvider>
        <UserContextProvider>
          <TeamContextProvider>
            <PaginationContextProvider>
              <PlanContextProvider>
                <BookContextProvider>
                  <NotesContextProvider>
                    <CodeBoxContextProvider>
                      <ChooseURLContextProvider>
                        <MainComponent className="App">
                          <AppLoader />
                          {/* <Helmet>
                          {allHeaderScriptContent && allHeaderScriptContent}
                        </Helmet> */}
                          <div ref={ref}></div>
                          <Main />
                        </MainComponent>
                      </ChooseURLContextProvider>
                    </CodeBoxContextProvider>
                  </NotesContextProvider>
                </BookContextProvider>
              </PlanContextProvider>
            </PaginationContextProvider>
          </TeamContextProvider>
        </UserContextProvider>
      </PublicContextProvider>
    </LoaderContextProvider>
  );
};

export default App;
