import axios from "axios";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import { ChatSource } from "../../../enums";
import SearchArrow from "../../../images/chat-arrow.svg";
import BackIcon from "../../../images/chat-back.svg";
import ChatBotIcon from "../../../images/chat-bot-icon.svg";
import UserImage from "../../../images/chat-user.svg";
import EPUBSign from "../../../images/epubSign.svg";
import FLACSign from "../../../images/flacSign.svg";
import HTMLSign from "../../../images/htmlSign.svg";
import LoaderGif from "../../../images/loader.gif";
import MDSign from "../../../images/mdSign.svg";
import MDXSign from "../../../images/mdxSign.svg";
import MP3Sign from "../../../images/mp3Sign.svg";
import MP4Sign from "../../../images/mp4Sign.svg";
import URLSign from "../../../images/ogg1.svg";
import OGGSign from "../../../images/oggSign.svg";
import PDFSign from "../../../images/pdf1.svg";
import YouTubeSign from "../../../images/ps_youtube.svg";
import TXTSign from "../../../images/txtSign.svg";
import TypingLoading from "../../../images/typingLoading.gif";
import DropChatIcon from "../../../images/drop-chat-icon.svg";
import WAVSign from "../../../images/wavIcon.svg";
import TextSign from "../../../images/txtSign.svg";
import myAxios from "../../../myaxios";
import { Book, User } from "../../../types";
import { isTestUser, parseErrorResponse } from "../../../utils/utils";
// import QuestionsSideBar from "./old-questions-sidebar";
import { DEVICE_MEDIUM, DEVICE_MOBILE } from "../../../constants/breakpoints";
import { PublicContext } from "../../../context/PublicContext";
import {
  ChatBotEvents,
  EventCategory,
  Source,
  SubCategory,
  UserEvents,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import { Mixpanel } from "../../../mixpanel";
import { PUBLIC_USER_CHATBOT } from "../../../utils/class-names";
import CustomMarkdown from "../../../utils/custom-markdown";
import { Chat } from "../../book-chat/chat";
import MagicLinkLoginPopup from "../../magic-link-login-popup";
import PublicQuestionsSideBar from "./../public-questions-sidebar";
import * as BookChatStyles from "./../styled-public-trial";
import styled from "styled-components";
import { BookTitle } from "../../book/styled";
import socket from "../../../dropchat-socket";
// eslint-disable-next-line

const Button = styled.button`
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 50%;
  background: #1585b5;
  color: #fff;

  &:hover {
    background: #0b5f82;
  }
  &:focus {
    background: #0b5f82;
  }
`;

const UserDemoChat = () => {
  const { deviceWidth } = useContext(PublicContext);
  const [currentSessionHistory, setCurrentSessionHistory] = useState<any[]>([]);
  const [publicUserQuestionAskedCount, setPublicUserQuestionAskedCount] =
    useState<number>(0);
  const [trialQuestionLimit, setTrialQuestionLimit] = useState<number>(5);
  const [isTrialQuestionLoaded, setTrialQuestionLoaded] =
    useState<boolean>(false);
  const { indexingProgress, setIndexingProgress } = useContext(PublicContext);

  const [allSessionHistory, setAllSessionHistory] = useState([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const [formRef, setFormRef] = useState<any>();
  const [questionValue, setQuestionValue] = useState("");

  const { bookId } = useParams();

  const navigate = useNavigate();

  // const validatePropmt = (prompt: string) => {
  //   let error;
  //   if (!prompt) {
  //     error = "This is Required!";
  //   }
  //   return error;
  // };
  const [user, setUser] = useState<User>();
  const [localBook, setLocalBook] = useState<Book>({});
  const [showOverlay, setShowOverlay] = useState(false);
  // console.log("localBook------------", localBook);
  const [fileProcessingMessage, setFileProcessingMessage] =
    useState<string>("");

  useEffect(() => {
    if (bookId) {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/getFileProcessingMessage"
        )
        .then((response) => {
          if (response && response.data) {
            setFileProcessingMessage(response.data.data);
          }
        });
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/get-trial-question-limit"
        )
        .then((response) => {
          if (response && response.data && response.data.data > 0) {
            setTrialQuestionLoaded(true);
            setTrialQuestionLimit(response.data.data);
          }
        });

      axios
        .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
        .then((response) => {
          // console.log("response-------", response);

          if (response.data) {
            setLocalBook(response.data);
            setUser(response.data.user);
            setPublicUserQuestionAskedCount(
              response.data.user.questionAskedCount
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.error("Gettings Error at the time of load book ", error);
        });
    }
  }, [setLocalBook, setUser, bookId]);

  useEffect(() => {
    if (user) {
      myAxios
        .get(
          process.env.REACT_APP_SERVER_URL +
            "/get-history-of-chats/" +
            user?.id +
            "/" +
            bookId
        )
        .then((response) => {
          if (response.data) {
            setAllSessionHistory(response.data.data.allSessionHistory);
            setCurrentSessionHistory(response.data.data.currentSessionHistory);
          }
        })
        .catch((error) => {
          // console.error(
          //   "Getting error at the time of get chat history ",
          //   error
          // );
        });
    }
  }, [setAllSessionHistory, bookId, user]);

  const getFullAnswer = async (id: any, userQuery: any) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/questions-and-answers/${id}`)
      .then((res: any) => {
        if (res.data) {
          const data = res.data.data;

          setCurrentSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;

              return [...oldQuestions];
            } else return [];
          });
          // checkAndShowLoginForm();
          if (!isTestUser(user?.email))
            Mixpanel.track("Public(Trial User) User Chat Response", {
              bookId: localBook._id ? localBook._id : localBook.id,
              bookTitle: localBook.title,
              bookAuthors: localBook.authors,
              question: userQuery,
              answer: data.answer,
              email: user?.email,
            });
          axios
            .put(
              process.env.REACT_APP_SERVER_URL +
                "/public/update-question-ask-count/" +
                user?._id
            )
            .then((response) => {
              if (response && response.data) {
                setUser(response.data.data);
              }
            })
            .catch((error) => {
              // console.error(
              //   "Gettings Error at the time of update question ask count ",
              //   error
              // );
            });
          setShowOverlay(false);
        } else {
          setShowOverlay(false);
          NotificationManager.error(
            res.data && res.data.message
              ? res.data.message
              : "Unable to process your request, please try later!"
          );
          if (!isTestUser(user?.email))
            Mixpanel.track("Public(Trial User) User Chat Response", {
              bookId: localBook._id ? localBook._id : localBook.id,
              bookTitle: localBook.title,
              bookAuthors: localBook.authors,
              question: userQuery,
              email: user?.email,
              answer: "Getting Error",
              errror:
                res.data && res.data.message
                  ? res.data.message
                  : "Unable to process your request, please try later!",
            });
        }
      })
      .catch((error) => {
        // handle error
        // console.error(
        //   "Getting Error at the time of ASK answer from chat gpt ",
        //   error
        // );
        setShowOverlay(false);
        NotificationManager.error(parseErrorResponse(error));
        if (!isTestUser(user?.email))
          Mixpanel.track("Public(Trial User) User Chat Response", {
            bookId: localBook._id ? localBook._id : localBook.id,
            bookTitle: localBook.title,
            bookAuthors: localBook.authors,
            question: userQuery,
            email: user?.email,
            answer: "Getting Error",
            errror: parseErrorResponse(error),
          });
      });
  };

  const chatWithDropchat = async (userQuery: string) => {
    setShowOverlay(true);
    setCurrentSessionHistory(
      currentSessionHistory
        ? [...currentSessionHistory, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );
    if (!isTestUser(user?.email)) Mixpanel.identify(user?.email);
    if (!isTestUser(user?.email))
      Mixpanel.track("Public(Trial User) User Try to Chat", {
        bookId: localBook._id ? localBook._id : localBook.id,
        bookTitle: localBook.title,
        bookAuthors: localBook.authors,
        question: userQuery,
        email: user?.email,
      });
    trackEvent({
      email: getUsersEmail(user),
      eventCategory: EventCategory.CHATBOT,
      event: ChatBotEvents.USER_ASKED_QUESTION_AT_PUBLIC_CHATBOT,
      subCategory: SubCategory.EVENT,
      source: Source.PUBLIC,
      bookId: bookId,
      rawData: {
        question: userQuery,
      },
    });

    const requestBody = {
      question: userQuery,
      bookId: bookId,
      source: ChatSource.public,
    };

    const chat = new Chat(
      requestBody,
      (token: string) => {
        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        getFullAnswer(data, userQuery);
      },
      (data: any) => {
        NotificationManager.error(data);
      },
      ""
      // user?.jwt
    );

    await chat.startChat();
  };

  const submitQuestion = (values: any, actions: any) => {
    actions.resetForm({
      values: {
        prompt: "",
      },
    });

    if (publicUserQuestionAskedCount >= trialQuestionLimit) {
      setShowLoginPopup(true);
      trackEvent({
        email: getUsersEmail(user),
        eventCategory: EventCategory.CHATBOT,
        event: ChatBotEvents.USER_GOES_AVOVE_QUESTION_LIMIT_ON_PUBLIC_CHATBOT,
        subCategory: SubCategory.EVENT,
        source: Source.PUBLIC,
        bookId: bookId,
      });

      return;
    }
    chatWithDropchat(values.prompt);
    setPublicUserQuestionAskedCount((oldState) => oldState + 1);
  };

  const handleRefreshClick = (event: any) => {
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <BookChatStyles.MainContainer fluid>
        <BookChatStyles.LeftColumnDiv>
          <p style={{ fontSize: "24px", marginBottom: "10%" }}>
            Thank you! Your demo chatbot is ready. Type in questions to start
            testing it out!
          </p>
          <p
            style={{ fontSize: "16px", marginBottom: "10%", color: "#797979" }}
          >
            Welcome to your demo conversation! Feel free to ask questions based
            on the information you provided. If the chatbot doesn’t have an
            answer, it’s likely because that information wasn’t included.
          </p>
          <Button onClick={handleRefreshClick}>Try Demo Again</Button>
        </BookChatStyles.LeftColumnDiv>
        <BookChatStyles.RightColumn xs={deviceWidth === DEVICE_MOBILE ? 3 : 9}>
          {/* <BookChatStyles.ChatBarHeader>
            {localBook.title ? localBook.title : "Header"}
          </BookChatStyles.ChatBarHeader> */}
          <BookChatStyles.ChatBarHeader
            style={{ justifyContent: "start", color: "#fff" }}
          >
            <img style={{ height: "30px" }} src={DropChatIcon} alt="" />{" "}
            &nbsp;&nbsp;{localBook.title ? localBook.title : "Header"}
          </BookChatStyles.ChatBarHeader>

          <BookChatStyles.ChatInputBox sm={12}>
            <BookChatStyles.RightContentRow style={{marginLeft: '0',}}>
              <BookChatStyles.ChatBotContent sm={12}>
                <BookChatStyles.ScrollDiv>
                  <BookChatStyles.QuestionAnswerSection
                    style={{ marginTop: "0" }}
                  >
                    {/* {loading && (
                        <BookChatStyles.StatusLoaderSection>
                          <img src={LoaderGif} alt="loading..." width={60} />
                          <BookChatStyles.WaitTitle>
                            Please Wait
                          </BookChatStyles.WaitTitle>
                        </BookChatStyles.StatusLoaderSection>
                      )} */}

                    {currentSessionHistory &&
                      currentSessionHistory.length > 0 &&
                      currentSessionHistory.map((data: any) => (
                        <BookChatStyles.SignleQuestionAnswerSection>
                          <BookChatStyles.QuestionSection>
                            <img
                              width={
                                deviceWidth === DEVICE_MOBILE ? "15" : "30"
                              }
                              src={UserImage}
                              alt="user_image"
                            />
                            &nbsp;&nbsp;
                            <h5
                              style={{
                                fontSize:
                                  deviceWidth === DEVICE_MOBILE
                                    ? "0.75rem"
                                    : "1rem",
                              }}
                            >
                              {data.question}
                            </h5>
                          </BookChatStyles.QuestionSection>
                          <BookChatStyles.ResponseSection>
                            <div>
                              <img
                                width={
                                  deviceWidth === DEVICE_MOBILE ? "15" : "30"
                                }
                                src={ChatBotIcon}
                                alt="user_image"
                                style={{
                                  display: "flex",
                                  alignSelf: "baseline",
                                }}
                              />
                              &nbsp;&nbsp;
                              {data.answer && data.answer.length > 0 ? (
                                <BookChatStyles.MarkDownContent>
                                  <CustomMarkdown text={data.answer} />
                                </BookChatStyles.MarkDownContent>
                              ) : (
                                <h5 style={{ margin: 0 }}>
                                  {`${data.answer}`}
                                  {data._id ? null : (
                                    <img
                                      width={
                                        deviceWidth === DEVICE_MOBILE
                                          ? "15"
                                          : "30"
                                      }
                                      src={TypingLoading}
                                      alt="..."
                                    />
                                  )}
                                </h5>
                              )}
                            </div>
                          </BookChatStyles.ResponseSection>
                        </BookChatStyles.SignleQuestionAnswerSection>
                      ))}
                  </BookChatStyles.QuestionAnswerSection>
                </BookChatStyles.ScrollDiv>
              </BookChatStyles.ChatBotContent>
              <BookChatStyles.FieldColumn sm={12}>
                {/* {(showOverlay ||
                  (localBook.chatType === "dropchat" &&
                    localBook.indexStatus !== 2)) && (
                  <BookChatStyles.OverlayFieldColumn
                    sm={12}
                  ></BookChatStyles.OverlayFieldColumn>
                )} */}

                <Formik
                  initialValues={{
                    prompt: questionValue,
                  }}
                  onSubmit={(values, actions) => {
                    setQuestionValue("");
                    submitQuestion(values, actions);
                  }}
                  enableReinitialize
                >
                  {({ errors, touched, isValidating }: any) => (
                    <BookChatStyles.BookFetchForm
                      ref={(el) => setFormRef(el)}
                      style={{ padding: "0", paddingBottom: "6px" }}
                      className={PUBLIC_USER_CHATBOT}
                    >
                      {isTrialQuestionLoaded && (
                        <BookChatStyles.RestrictedSection
                          style={{
                            margin: "0 0 0.5rem 0",
                            justifyContent: "start",
                          }}
                        >
                          <BookChatStyles.RemainingCount
                            xs={deviceWidth === DEVICE_MOBILE ? 8 : 3}
                          >
                            {isTrialQuestionLoaded && (
                              <>
                                {publicUserQuestionAskedCount
                                  ? trialQuestionLimit -
                                      publicUserQuestionAskedCount <
                                    0
                                    ? 0
                                    : trialQuestionLimit -
                                      publicUserQuestionAskedCount
                                  : trialQuestionLimit}{" "}
                                / {trialQuestionLimit} Demo questions remaining
                              </>
                            )}
                          </BookChatStyles.RemainingCount>
                          <BookChatStyles.LoginLink
                            xs={deviceWidth === DEVICE_MOBILE ? 4 : 2}
                            onClick={() => {
                              trackEvent({
                                email: getUsersEmail(user),
                                eventCategory: EventCategory.USER,
                                event:
                                  UserEvents.USER_TRY_TO_LOGIN_FROM_PUBLIC_CHATBOT,
                                subCategory: SubCategory.EVENT,
                                source: Source.PUBLIC,
                                bookId: bookId,
                              });
                              navigate("/magic-login");
                            }}
                          >
                            Login to Dropchat
                          </BookChatStyles.LoginLink>
                        </BookChatStyles.RestrictedSection>
                      )}
                      <BookChatStyles.FieldSection>
                        <BookChatStyles.FormInput
                          name="prompt"
                          as="textarea"
                          value={questionValue}
                          placeholder={
                            'Ask me anything from "' + localBook.title + '"'
                          }
                          onChange={(e) => setQuestionValue(e.target.value)}
                          onKeyDown={(e) => {
                            // eslint-disable-next-line
                            if (e.keyCode == 13 && e.shiftKey == false) {
                              e.preventDefault();
                              formRef.requestSubmit();
                            }
                          }}
                          style={{
                            paddingRight: "2.6rem",
                            resize: "none",
                            height: "46px",
                            padding: "11px",
                          }}
                        />
                        <BookChatStyles.FetchBookButton
                          type="submit"
                          style={{ top: "10px" }}
                        >
                          <img width={25} src={SearchArrow} alt="search" />
                        </BookChatStyles.FetchBookButton>
                      </BookChatStyles.FieldSection>
                    </BookChatStyles.BookFetchForm>
                  )}
                </Formik>
              </BookChatStyles.FieldColumn>
            </BookChatStyles.RightContentRow>
          </BookChatStyles.ChatInputBox>
        </BookChatStyles.RightColumn>

        {showLoginPopup && (
          <>
            <Modal show={true} backdrop="static" keyboard={false}>
              <MagicLinkLoginPopup
                showLoginPopup={showLoginPopup}
                setShowLoginPopup={setShowLoginPopup}
              />
            </Modal>
          </>
        )}
      </BookChatStyles.MainContainer>
    </>
  );
};

export default UserDemoChat;
