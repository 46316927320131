import { Field, Form } from "formik";
import { OverlayPanel } from "primereact/overlaypanel";
import { TreeSelect } from "primereact/treeselect";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
const { innerWidth: width } = window;
const isMobileWidth = width < 1000;

interface Selected {
  selected: boolean;
}

export const MainContainer = styled(Container)`
  padding: 0;
  border-top: 1px #21232c48 solid;
`;

export const Sidebar = styled(Col)`
  background: #2f3349;
  flex-direction: column;
  height: calc(100vh - 2px);
  overflow: auto;
`;

export const ChatBarHeader = styled.div`
  height: 50px;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #1585B5;
  margin: 0;

`;

export const SideBarHeader = styled.div`
  height: 70px;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const SideBarContent = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;
export const LogoutButton = styled.img`
  padding: 0;
  width: 30%;
  margin: auto;
  cursor: pointer;
`;

export const BackButton = styled.img`
  padding: 0;
  width: 15px;

  cursor: pointer;
`;

export const BookFetchForm = styled(Form)`
  /* margin: auto; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  margin: 0;
  align-self: end;
`;

export const GreetingText = styled.p`
  color: #33303cde;
  font-size: 12px;
  margin: 0.2rem 0rem;
`;

export const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
`;

export const DummyFormInput = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  &::placeholder {
    font-size: 14px;
  }
`;

export const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  &::placeholder {
    font-size: 14px;
  }
`;
export const DummyButton = styled.div`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  top: 0.8rem;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
`;
export const FetchBookButton = styled.button`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  cursor: pointer;
  top: 0.8rem;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
`;

export const ChatInputBox = styled(Col)`
  flex: 1;
`;

export const DummyChatInputBox = styled.div`
  justify-content: center;
`;

export const ChatInputBoxSampleQuestion = styled(Col)`
  flex: 1;
  padding-top: ${isMobileWidth ? "50px" : "0px"};
`;

export const ChatHistoryHeading = styled.h5`
  color: #cccfdd;
`;

export const RightColumn = styled(Col)`
  flex-direction: column;
  background-color: #f8f7fa;
`;

export const LeftColumnDiv = styled(Col)`
margin: 1%;
  align-items: center;
  flex-direction: column;
  background-color: #f8f7fa;
`;

export const RightContentRow = styled(Row)`
  width: 100%;
  padding: 0px 0px 0px 0px;
  background-color: #fff;
  /* margin: 15px; */
  margin-top: 2px;
  margin-bottom: 0;
  border-radius: 5px;
  margin: 2px;
`;

export const ChatBotContent = styled(Col)`
  flex-direction: column;
  padding-bottom: 10px;
`;

export const ScrollDiv = styled(ScrollToBottom)`
  height: ${isMobileWidth ? "78vh" : "73vh"};
  width: 100%;
  flex-direction: column;
  padding-top: ${isMobileWidth ? "50px" : "0px"};
`;

export const ScrollDivForChatPage = styled(ScrollToBottom)`
  height: ${isMobileWidth ? "85vh" : "73vh"};
  width: 100%;
  flex-direction: column;
  padding-top: ${isMobileWidth ? "50px" : "0px"};
  padding-bottom: ${isMobileWidth ? "40px" : "0px"};
`;

export const BookContent = styled.div`
  flex-direction: column;
`;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px;
  flex-direction: column;
  width: 100%;
`;

export const ResponseSection = styled.div`
  padding: 10px;
  color: #282c34;
  background: #f8f7fa;
  box-shadow: 1px 8px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 8px 8px;
  align-items: center;
  margin-top: ${isMobileWidth ? "6px" : "0px"};
  margin-bottom: 0px;
  align-items: flex-start;
  flex-direction: column;
`;

export const QuestionSection = styled.div`
  padding: 10px;
  background: #f8f7fa;
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 1rem;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
  margin-top: auto;
`;

export const HistoryQuestion = styled.div`
  align-items: center;
  padding: 10px;
  margin: 0.2rem 0rem;
  background: ${(props: Selected) => (props.selected ? "#3d4156" : "")};
  border-radius: 5px;
  cursor: pointer;
  & p {
    color: #9c9eaf;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & img {
    margin-right: 0.8rem;
  }
`;

export const FieldColumn = styled(Col)`
  position: relative;
`;

export const FieldColumnForChatPage = styled(Col)`
  position: ${isMobileWidth ? "fixed" : "relative"};
  width: ${isMobileWidth ? "100%" : "100%"};
  bottom: 0;
`;

export const OverlayFieldColumn = styled(Col)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 999;
  background: #00000050;
  cursor: not-allowed;
`;

export const StatusLoaderPopup = styled(Col)`
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
`;

export const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: #0001;
`;

export const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

export const WaitDescription = styled.p`
  text-align: center;
`;

export const ChatPageHeader = styled.div`
  background-color: #f8f7fa;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0.2rem 0rem 0.2rem 1.5rem;
  position: ${isMobileWidth ? "fixed" : "relative"};
  width: 100%;
  top: 0;
  z-index: 9999;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: flex-start;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: flex-start;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: flex-start;
  }
`;

export const RestrictedSection = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const RemainingCount = styled(Col)`
  color: #ea5455;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  background: #ffe1e1;
  border-radius: 6px;
  padding: 0.2rem 0rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

export const LoginLink = styled(Col)`
  color: #1585b5;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

export const NoteListTitle = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
`;

export const SelectField = styled.select`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
  border: none;
  width: 20px;
`;

export const SelectOption = styled.option`
  font-size: 14px;
  color: #33303c;
  padding: 5px 20px 5px 5px;
  &:hover {
    background: #1585b5;
  }
`;

export const TreeSelectStyle = styled(TreeSelect)`
  font-size: 14px;
  border: 0;
  background: #f8f7fa;

  & span {
    font-size: 1rem;
    color: #1585b5;
    font-weight: bold;
  }
`;
export const AddCategoryStyle = styled.p`
  font-size: 14px;
  color: #1585b5;
  font-weight: bold;
  margin: 0;
  margin-left: 3rem;
  cursor: pointer;
  align-self: center;
`;

export const OverlayPanelStyle = styled(OverlayPanel)`
  flex-direction: column;
`;

export const AddCategoryForm = styled(Form)`
  padding: 1.25rem;
  padding-top: 0;
  & input {
    margin: 0.5rem 0rem;
    background: #ffffff;
    border: 1px solid rgba(51, 48, 60, 0.4);
    border-radius: 4px;
    color: rgba(51, 48, 60, 0.6);
    padding: 5px;
    font-size: 14px;
  }
  & label {
    color: rgba(51, 48, 60, 0.8);
    font-size: 14px;
    font-weight: bold;
  }
  & button {
    background: #1585b5;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-color: #1585b5;
    box-shadow: none;
  }
`;

export const AddCategoryHeading = styled.h6`
  color: #33303c;
  font-weight: 600;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e6e6e7;
`;

export const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-size: 12px;
`;

export const ChipParent = styled.div`
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.75rem;
  cursor: pointer;
`;
export const CrossIcon = styled.span`
  font-size: 1rem !important;
  font-weight: bold;
  display: flex;
  align-self: center;
  margin-left: 0.5rem;
`;

export const Column = styled(Col)`
  display: flex;
  justify-content: end;
`;

export const Wrapper = styled.div`
  padding-left: 27px;
`;
export const WrapperHeader = styled.div`
  padding-left: 10px;
  width: 77px;
`;
export const DowngradeInfoText = styled.p<{ limitCount: boolean }>`
  font-weight: 700;
  color: ${(props) => (props.limitCount ? "#EC2D2D" : "#11b753")};
  margin-bottom: 5px;
`;
export const DowngradeContainer = styled.div<{ limitCount: boolean }>`
  padding: 5px 25px 0px 20px;
  margin-bottom: 5px;
  display: flex;
  // width: 300px;
  background: ${(props) => (props.limitCount ? "#EC2D2D1A" : "#11b75326")};
  border-radius: 6px;
`;
export const UpgradeSpan = styled.span`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  cursor: pointer;
`;

export const SourceElements = styled.p`
  font-size: 14px;
  color: #1585b5;
  font-weight: bold;
  margin: 0;
  margin-left: 3rem;
  cursor: pointer;
  align-self: center;
`;

export const LeadFormScrollDiv = styled(ScrollToBottom)`
  height: auto;
  width: 100%;
  flex-direction: column;
  padding-top: ${isMobileWidth ? "50px" : "0px"};
`;

export const LeadChatForm = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

export const FormHeading = styled.p`
  font-weight: 600;
  color: #33303c;
  font-size: 16px;
`;

export const FormConditionalHeading = styled.p`
  font-weight: 600;
  color: #1585b5;
  font-size: 20px;
`;

export const InputSection = styled.div`
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const FormLabelText = styled.span`
  color: #33303ccc;
  font-size: 16px;
  font-weight: 500;
`;

export const FormLeadInput = styled.input`
  height: 45px;
  border: 1px solid #33303c33;
  padding: 10px;
`;

export const SkipText = styled.p`
  color: #1585b5;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const PoweredText = styled.p`
  color: #33303c4d;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

export const SaveButton = styled.button`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 0;
  width: 100%;
`;
export const SuggestedQuestionHeading = styled.div`
  justify-content: space-between;
  margin-bottom: 10px;
  & p {
    color: #33303c;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const MarkDownContent = styled.div`
  color: ${(props) => props.color};
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
  & h1 {
    color: ${(props) => props.color};
  }
  & h2 {
    color: ${(props) => props.color};
  }
  & h3 {
    color: ${(props) => props.color};
  }
  & h4 {
    color: ${(props) => props.color};
  }
  & h5 {
    color: ${(props) => props.color};
  }
  & h6 {
    color: ${(props) => props.color};
  }
  & p {
    color: ${(props) => props.color};
    margin: 0;
  }
  & span {
    color: ${(props) => props.color};
  }
  & label {
    color: ${(props) => props.color};
  }
  & table {
    color: ${(props) => props.color};
  }
  & td {
    color: ${(props) => props.color};
  }
  & tr {
    color: ${(props) => props.color};
  }
  & th {
    color: ${(props) => props.color};
  }
  & li {
    color: ${(props) => props.color};
  }
`;