import React, { useState } from "react";
import { FieldSection } from "../styled-public";
import styled from "styled-components";
import { Field, useFormikContext } from "formik";

const FieldLabel = styled.label`
  margin-top: 2%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
`;

const RadioInput = styled.input`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
`;

const FormInput = styled(Field)`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  margin-top: 1%;
`;

const RadioButtonLabel = styled.label`
  display: flex;
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 1%;
`;

const FormInputRadio = styled(Field)`
  cursor: pointer;
`;
const FormInputFile = styled.input`
  margin-top: 2%;
`;

const PartsDiv = styled.div`
  display: block;
  flex-direction: column;
`;

function URLPDFComponent({ setFieldValue, setFileObject }: any) {
  const { values } = useFormikContext<any>();
  const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);

  console.log("values inside url component", values);
  return (
    <>
      <h5>
        <b>Add Website and PDF Files</b>
      </h5>
      <h6>
        If you want to add any URL and PDF document, go ahead and use below
        fields.
      </h6>

      <FieldSection style={{ marginTop: "3%" }}>
        <PartsDiv>
          <RadioButtonLabel>
            <RadioInput
              type="radio"
              name="selectedRadio"
              checked={!isUrlSelected}
              onChange={() => {
                setFieldValue(`selectedRadio`, "file");
                setIsUrlSelected(false);
              }}
            />
            <span style={{ marginLeft: "1%" }}>Upload PDF</span>
          </RadioButtonLabel>

          <div>
            <FieldSection style={{ width: "40%" }}>
              <FieldLabel>Company File Title</FieldLabel>
              <FormInput
                name="fileTitle"
                type="text"
                disabled={isUrlSelected}
                onChange={(e: any) => {
                  setFieldValue(`fileTitle`, e.target.value);
                }}
              />
            </FieldSection>

            <FieldSection style={{ marginLeft: "3%" }}>
              <FieldLabel>Upload PDF</FieldLabel>
              <FormInputFile
                name="file"
                type="file"
                accept=".pdf"
                disabled={isUrlSelected}
                onChange={(e: any) => {
                  console.log("e", e.target.files[0]);
                  setFileObject(e.target.files[0]);
                }}
              />
            </FieldSection>
          </div>
        </PartsDiv>

        <br />
        <hr />

        <PartsDiv>
          <RadioButtonLabel>
            <RadioInput
              type="radio"
              name="selectedRadio"
              checked={isUrlSelected}
              onChange={() => {
                setFieldValue(`selectedRadio`, "url");
                setIsUrlSelected(true);
              }}
            />
            <span style={{ marginLeft: "1%" }}>Website URLs</span>
          </RadioButtonLabel>

          <div>
            <FieldSection style={{ width: "50%" }}>
              <FieldLabel>URL Title</FieldLabel>
              <FormInput
                name="urlTitle"
                type="text"
                disabled={!isUrlSelected}
                onChange={(e: any) => {
                  setFieldValue(`urlTitle`, e.target.value);
                }}
              />
            </FieldSection>

            <FieldSection style={{ width: "50%", marginLeft: "4%" }}>
              <FieldLabel>Website URL</FieldLabel>
              <FormInput
                name="url"
                type="text"
                disabled={!isUrlSelected}
                onChange={(e: any) => {
                  setFieldValue(`url`, e.target.value);
                }}
              />
            </FieldSection>
          </div>
        </PartsDiv>
      </FieldSection>
    </>
  );
}

export default URLPDFComponent;
