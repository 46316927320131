import { useContext, useEffect, useState } from "react";
import { Col, Container, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { ChooseURLContext } from "../../context/choose-url-context";
import DltrossSvg from "../../images/white-cross.svg";
import TrashIcon from "../../images/trash-normal.svg";
import BluePlusIcon from "../../images/blue-plus-icon.svg";
import OrIcon from "../../images/oricon.svg";
import { Field, Form, Formik } from "formik";
import myAxios from "../../myaxios";
import { LoaderContext } from "../../context/loader-context";
import { parseErrorResponse } from "../../utils/utils";
import {
  DEVICE_MEDIUM,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { UrlData } from "../../types";
import { UserContext } from "../../context/UserContext";
import IndexCharacterLimitPopUp from "../index-character-limit-popup";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 95% !important;
  left: 50%;
  top: 48%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    overflow: scroll;
    position: relative;
    height: 90vh;
  }
  @media (min-width: ${TAB.minWidth}px) {
    overflow: scroll;
    position: relative;
    height: 90vh;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    overflow: hidden;
    height: max-content;
    position: fixed;
  }
`;
const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;

const DltHeader = styled.div`
  width: 100% !important;
  background: #1585b5;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-between;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const UrlsCheckbox = styled.div`
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
  justify-content: space-between;
  // border-bottom: 1px solid #eaeaea;
  & label {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  & img {
    width: 20px;
    cursor: pointer;
  }
`;
const UrlsCheckboxBorder = styled.div`
  flex-direction: row;
  align-items: center;
  // padding: 15px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  & label {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  & img {
    width: 20px;
    cursor: pointer;
  }
`;

const SubmitButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;

  &:hover {
    background: #0e328eb0;
    border: none;
  }
  &:disabled {
    display: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SubmitButtonColumn = styled(Col)`
  padding: 20px 15px 10px 15px;
  box-shadow: 0px -4px 5px 3px #00000030;
  justify-content: flex-end;
`;

const SelectedCount = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding-left: 1rem;
  align-items: center;
  display: flex;
`;

const URLFetchForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UrlFormLabel = styled(FormLabel)`
  font-weight: 500;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
`;

const NormalInput = styled.input`
  width: 100%;
  padding: 10px;
`;

const UrlSection = styled.div`
  padding: 1rem;
  flex-direction: column;
`;

const UrlSectionSeparation = styled.div`
  margin: 2rem 0;
  justify-content: center;
`;

const FetchCrawlUrlButton = styled.button`
  padding: 8px 30px 8px 30px;
  background: #1585b5;
  color: #fff;
  border: none;
  width: max-content;
`;

const ExtraUrlHeader = styled.div`
  justify-content: space-between;
`;

const DeleteText = styled.span`
  color: #ec2d2d;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
`;

const NormalFieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
`;

const SetExtraUrlButton = styled.button`
  background: #1585b5;
  color: #fff;
  border: none;
  width: max-content;
`;

const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 50vh;
  width: 50vw;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    bottom: 25vh;
    width: 80vw;
    right: 10vw;
  }
`;

const CustomButton = styled.button`
  background: #f2ae01;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;
  margin-right: 1rem;
  &:disabled {
    display: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const UrlSelectionBox = ({
  createUrlBook,
  popupUrlContent,
  isLoggedIn,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const { user, updateUserSubscriptionIndexCount } = useContext(UserContext);
  const {
    showConfirmationBox,
    setShowConfirmationBox,
    setUrlList,
    urlList,
    resetForm,
    createBookInput,
  } = useContext(ChooseURLContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [urls, setUrls] = useState<UrlData[]>([]);
  const [onChangeExtraUrl, setOnChangeExtraUrl] = useState<string>("");

  useEffect(() => {
    setUrls(urlList);
  }, [urlList]);
  const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };
  const [disableButtonState, setDisableButtonState] = useState<boolean>(false);
  const [showLimitPopUp, setShowLimitPopUp] = useState<boolean>(false);

  useEffect(() => {
    if (urls.length > 0 && isLoggedIn) {
      const indexingCharacterLimitCount = Math.max(
        (user?.currentSubscription?.meta?.indexCharacterSupport
          ? user?.currentSubscription?.meta?.indexCharacterSupport
          : 0) -
          (user?.currentSubscription?.indexCharacterUsedTotal
            ? user?.currentSubscription?.indexCharacterUsedTotal
            : 0),
        0
      );
      const urlsCharacterCount = urls.reduce(
        (total, urlData) => total + urlData.characterCount,
        user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0
      );
      if (urlsCharacterCount > indexingCharacterLimitCount) {
        setDisableButtonState(true);
      } else {
        setDisableButtonState(false);
      }
    }
    // eslint-disable-next-line
  }, [urls, user, setDisableButtonState]);

  const validateUrl = (value: string) => {
    // console.log("value is, ", value);
    return /^(?:(?:(?:https?|ftp|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const validateUrlSubmission = (formData: any) => {
    const errors: any = {};
    if (!formData.crawlUrl) {
      errors.crawlUrl = "Please enter any URL!";
    }
    if (!isValidHttpUrl(formData.crawlUrl)) {
      errors.crawlUrl = "valid URL is required!";
    }
    if (!validateUrl(formData.crawlUrl)) {
      errors.crawlUrl = "valid URL is required!";
    }
    return errors;
  };

  const validateSitemapUrlSubmission = (formData: any) => {
    const errors: any = {};
    if (!formData.sitemapUrl) {
      errors.sitemapUrl = "Please enter sitemap/XML URL!";
    }
    if (!isValidHttpUrl(formData.sitemapUrl)) {
      errors.sitemapUrl = "valid URL is required!";
    }
    if (!validateUrl(formData.sitemapUrl)) {
      errors.sitemapUrl = "Please enter valid sitemap/XML URL!";
    }
    return errors;
  };

  const removeUrl = (idx: number) => {
    setUrls([
      ...urls.filter((item, index) => {
        return index !== idx;
      }),
    ]);
  };

  const removeAllUrl = () => {
    setUrls([]);
  };

  const fetchUrls = (url: any) => {
    // console.log("url is, ", url);
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/books/urls", {
        url,
      })
      .then((response) => {
        if (response.data && response.data.success) {
          setShowLoader(false);
          setUrls(response.data.data);
          if (response.data.data.length <= 0) {
            NotificationManager.info(
              "No URLs found on provided location or it provides URLs in the format that is not yet supported."
            );
          }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return showConfirmationBox ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowConfirmationBox(false);
                setUrlList([]);
              }}
            />
            <SpanDltPopup>Select Preferred URLs</SpanDltPopup>
          </DltHeader>
          <Container>
            <Row
              style={{
                width: deviceWidth === DEVICE_MEDIUM ? "100%" : "inherit",
              }}
            >
              <Col
                md={6}
                xs={12}
                style={{ border: "1px solid rgba(47, 51, 73, 0.2)" }}
              >
                <UrlSection>
                  <Formik
                    initialValues={{
                      crawlUrl:
                        popupUrlContent && popupUrlContent.type === "web"
                          ? popupUrlContent.url
                          : "",
                    }}
                    validate={validateUrlSubmission}
                    onSubmit={(values, { resetForm }) => {
                      // console.log("values are, ", values);
                      fetchUrls(values.crawlUrl);
                    }}
                  >
                    {({ values, errors, touched }: any) => (
                      <URLFetchForm>
                        <UrlFormLabel>Crawl</UrlFormLabel>
                        <FieldSection>
                          <FormInput
                            name="crawlUrl"
                            placeholder="https://dropchat.co/"
                            value={popupUrlContent}
                          />
                          <span>
                            This will crawl all the links starting with the URL
                            (not including files on the website).
                          </span>
                        </FieldSection>
                        {errors.crawlUrl && touched.crawlUrl && (
                          <FormError className="formError">
                            {errors.crawlUrl}
                          </FormError>
                        )}
                        <FetchCrawlUrlButton type="submit">
                          Fetch links
                        </FetchCrawlUrlButton>
                      </URLFetchForm>
                    )}
                  </Formik>
                  <UrlSectionSeparation>
                    <img src={OrIcon} alt="or" width="100%" />
                  </UrlSectionSeparation>
                  <Formik
                    initialValues={{
                      sitemapUrl:
                        popupUrlContent && popupUrlContent.type === "xml"
                          ? popupUrlContent.url
                          : "",
                    }}
                    validate={validateSitemapUrlSubmission}
                    onSubmit={(values, { resetForm }) => {
                      // console.log("values are, ", values);
                      fetchUrls(values.sitemapUrl);
                    }}
                  >
                    {({ values, errors, touched }: any) => (
                      <URLFetchForm>
                        <UrlFormLabel>Submit Sitemap</UrlFormLabel>
                        <FieldSection>
                          <FormInput
                            name="sitemapUrl"
                            placeholder="Enter URL"
                            
                          />
                          <span>.XML URL with links to crawl.</span>
                        </FieldSection>
                        {errors.sitemapUrl && touched.sitemapUrl && (
                          <FormError className="formError">
                            {errors.sitemapUrl}
                          </FormError>
                        )}
                        <FetchCrawlUrlButton type="submit">
                          Fetch links
                        </FetchCrawlUrlButton>
                      </URLFetchForm>
                    )}
                  </Formik>
                </UrlSection>
              </Col>
              <Col md={6} xs={12} style={{ flexDirection: "column" }}>
                <UrlSection>
                  <ExtraUrlHeader>
                    <UrlFormLabel>Included Links</UrlFormLabel>
                    <DeleteText onClick={() => removeAllUrl()}>
                      Delete All
                    </DeleteText>
                  </ExtraUrlHeader>
                  <NormalFieldSection>
                    <NormalInput
                      name="extraUrl"
                      placeholder="Enter URL"
                      onChange={(e) => {
                        setOnChangeExtraUrl(e.target.value);
                      }}
                      value={onChangeExtraUrl}
                    />
                    <SetExtraUrlButton type="button">
                      <img
                        src={BluePlusIcon}
                        alt="add"
                        onClick={() => {
                          myAxios
                            .post(
                              process.env.REACT_APP_SERVER_URL + "/books/url",
                              {
                                url: onChangeExtraUrl,
                              }
                            )
                            .then((response) => {
                              setOnChangeExtraUrl("");
                              if (
                                !isValidHttpUrl(onChangeExtraUrl) ||
                                !validateUrl(onChangeExtraUrl)
                              ) {
                                NotificationManager.error(
                                  "valid URL is required!"
                                );
                              } else if (
                                urls.some(
                                  (urlData) => urlData.url === onChangeExtraUrl
                                )
                              ) {
                                NotificationManager.warning(
                                  onChangeExtraUrl +
                                    " is already available in the list!"
                                );
                              } else {
                                let allUrls = [...urls];
                                allUrls.unshift(response.data.data);
                                setUrls(allUrls);
                              }
                            });
                        }}
                      />
                    </SetExtraUrlButton>
                  </NormalFieldSection>
                  <Col
                    style={{
                      flexDirection: "column",
                      overflowY: "auto",
                      maxHeight: "60vh",
                      paddingRight: "20px",
                    }}
                  >
                    {urls &&
                      urls.length > 0 &&
                      urls.map((url: UrlData, index: number) => {
                        return (
                          <div style={{ flexDirection: "column" }}>
                            <UrlsCheckbox>
                              <label htmlFor={url.url}> {url.url}</label>
                              <img
                                src={TrashIcon}
                                alt="remove"
                                onClick={() => removeUrl(index)}
                              />
                            </UrlsCheckbox>
                            {isLoggedIn && (
                              <UrlsCheckboxBorder>
                                <label style={{ color: "grey" }}>
                                  {" "}
                                  ({url.characterCount}) Characters
                                </label>
                              </UrlsCheckboxBorder>
                            )}
                          </div>
                        );
                      })}
                  </Col>
                </UrlSection>
              </Col>
            </Row>
          </Container>
          <SubmitButtonColumn>
            <SelectedCount
              style={{
                display: isLoggedIn ? "flex" : "none",
                paddingRight: "1rem",
                color: "red",
                fontSize: "12px",
              }}
            >
              {urls && urls.length > 0 && disableButtonState ? (
                urls.reduce(
                  (total, urlData) => total + urlData.characterCount,
                  0
                ) +
                "/" +
                `${Math.max(
                  (user?.currentSubscription?.meta?.indexCharacterSupport
                    ? user?.currentSubscription?.meta?.indexCharacterSupport
                    : 0) -
                    (user?.currentSubscription?.indexCharacterUsedTotal
                      ? user?.currentSubscription?.indexCharacterUsedTotal
                      : 0),
                  0
                )}` +
                " Characters limit exceeds"
              ) : (
                <SelectedCount
                  style={{
                    paddingRight: "1rem",
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  {urls.reduce(
                    (total, urlData) => total + urlData.characterCount,
                    0
                  ) +
                    "/" +
                    `${Math.max(
                      (user?.currentSubscription?.meta?.indexCharacterSupport
                        ? user?.currentSubscription?.meta?.indexCharacterSupport
                        : 0) -
                        (user?.currentSubscription?.indexCharacterUsedTotal
                          ? user?.currentSubscription?.indexCharacterUsedTotal
                          : 0),
                      0
                    )}` +
                    " Characters"}
                </SelectedCount>
              )}
            </SelectedCount>
            <CustomButton
              type="button"
              disabled={!disableButtonState}
              onClick={() => {
                setShowLimitPopUp(true);
              }}
            >
              Upgrade Plan
            </CustomButton>
            <SubmitButton
              type="button"
              disabled={disableButtonState}
              onClick={() => {
                if (urls.length === 0) {
                  NotificationManager.error("No URL selected!");
                  return;
                }
                setShowConfirmationBox(false);
                createUrlBook(
                  {
                    ...createBookInput,
                    url: urls.map((urlData) => urlData.url),
                    baseUrl: popupUrlContent && popupUrlContent.url,
                  },
                  resetForm
                );
                setUrlList([]);
                updateUserSubscriptionIndexCount(
                  urls.reduce(
                    (total, urlData) => total + urlData.characterCount,
                    user?.currentSubscription?.indexCharacterUsedTotal
                      ? user?.currentSubscription?.indexCharacterUsedTotal
                      : 0
                  )
                );
              }}
            >
              Submit
            </SubmitButton>
            <SelectedCount>
              {urls && urls.length > 1
                ? urls.length + " URLs"
                : urls.length + " URL"}
            </SelectedCount>
          </SubmitButtonColumn>
        </DivForDltPopUp>
        {showLimitPopUp ? (
          <ParentDiv>
            <EmptyDiv
              onClick={() => {
                setShowLimitPopUp(false);
              }}
            ></EmptyDiv>
            <SubscriptionExpireOverlay>
              <IndexCharacterLimitPopUp
                titleContent={"Website character limit exceeded"}
                subHeadingContent={"Website character limit exceeded"}
                mainContent={`Please note that you have exceeded the website URL character limit of
                   your current subscription plan. To resolve this, you can either remove
                   some of the existing links or consider upgrading your plan for a
                   higher character limit.`}
                setShowLimitPopUp={setShowLimitPopUp}
              ></IndexCharacterLimitPopUp>
            </SubscriptionExpireOverlay>
          </ParentDiv>
        ) : null}
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default UrlSelectionBox;
